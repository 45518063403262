import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { SliceContainer } from "../components/design-system/Slices/SliceContainer/SliceContainer";
import { components } from "../components/slices";
import { Layout } from "../components/layout/Layout";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Seo } from "../components/Seo";
import { darkTheme } from "../theme/theme";

const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";

interface EmployerPageProps {
  data: any;
}

function EmployerPage({ data }: EmployerPageProps) {
  if (!data) return null;
  const doc = data.prismicEmployer.data;
  const templateDoc = data.prismicEmployerPage.data;

  return (
    <Layout
      header={templateDoc.hide_header !== true}
      altTheme={templateDoc.page_mode ? darkTheme : undefined}
    >
      <SliceContainer>
        <SliceZone
          slices={templateDoc.slices}
          components={components}
          context={doc}
        />
      </SliceContainer>
    </Layout>
  );
}

export const EmployerPageTemplateWithPreview = withPrismicPreview(EmployerPage);

export default allowPreview ? EmployerPageTemplateWithPreview : EmployerPage;

export const Head = ({ data }: EmployerPageProps) => {
  const { url } = data.prismicEmployer;
  const { disallow_indexing, page_title, page_description, og_image } =
    data.prismicEmployer.data;
  const hasImage = og_image?.localFile !== null;
  const gatsbyImageData =
    hasImage && og_image?.localFile?.childImageSharp.gatsbyImageData;

  return (
    <Seo
      noIndex={disallow_indexing}
      title={page_title}
      description={page_description}
      url={url}
      image={
        hasImage && gatsbyImageData
          ? {
              src: gatsbyImageData.images.fallback.src,
              height: gatsbyImageData.height,
              width: gatsbyImageData.width,
            }
          : {
              src: `/static/og-images/${data.prismicEmployer.url.replaceAll(
                "/",
                ""
              )}.png`,
              height: 630,
              width: 1200,
            }
      }
    />
  );
};

export const query = graphql`
  query ($uid: String!, $templateUid: String!) {
    prismicEmployer(uid: { eq: $uid }) {
      _previewable
      id
      url
      type
      uid
      url
      data {
        description
        disallow_indexing
        title
        og_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
        employer_display_name
        referral_code
        join_date
        employer_logo {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
        template {
          id
          uid
        }
      }
    }
    prismicEmployerPage(uid: { eq: $templateUid }) {
      _previewable
      id
      uid
      data {
        hide_header
        page_mode
        slices {
          ...HeaderDefault
          ...HeaderCustomNav
          ...FeaturedInLogosDefault
          ...TextImageTextImage
          ...TextImageImageText
          ...TextRowDefault
          ...TextRowFullWidth
          ...SpacerDefault
          ...FeaturedHeroDefault
          ...FeaturedHeroTube
          ...FeaturedHeroImage
          ...FeaturedHeroFeatured
          ...FeaturedContentSelectorDefault
          ...ThreeColumnTextDefault
          ...TextOnImageDefault
          ...TextOnImageFullWidth
          ...TypeformEmbedDefault
          ...TypeformEmbedPopup
          ...TypeformEmbedSlide
          ...TypeformEmbedFullPage
          ...TestimonialsDefault
          ...TestimonialsSingle
          ...TestimonialsDual
          ...FaqsDefault
          ...FaqsContained
          ...ThreeColumnTextDefault
          ...ThreeColumnTextBoxed
          ...FeaturedInsightsDefault
          ...InPageNavigationDefault
          ...DeepLinkDefault
          ...FeaturedVideoDefault
          ...TextRowWithBorder
          ...FeaturedFundsDefault
          ...FeaturedContentCarouselDefault
        }
      }
    }
  }
`;
